<template>
  <v-sheet flat>
    <v-card flat class="ma-1">
      <v-card-text class="px-10">
        <v-row dense class="my-2">
          <v-col cols="12">
            <span class="title font-weight-bold">Link to User</span>
          </v-col>
        </v-row>
        <v-row dense class="my-2">
          <v-col cols="12" md="6">
            <v-btn-toggle
              v-model="userSelection"
              borderless
              mandatory
              color="primary"
            >
              <v-btn value="1" block @click="userRelationModal = true">
                Select a User to link this Project to
              </v-btn>
              <v-btn
                value="2"
                class="ml-10"
                block
                @click="createRelation({ id: authenticatedUser.pk })"
              >
                This Project is for me
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense class="my-2">
          <v-col cols="12">
            <span class="title font-weight-bold">User Information</span>
          </v-col>
        </v-row>
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12">
            <span class="subtitle-1 mb-0">Full Name *</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              name="fullname"
              type="text"
              autocomplete="off"
              v-model="formFullName"
              outlined
              dense
              data-test="fullname"
              readonly
              class="mt-1 subtitle-1"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12">
            <span class="subtitle-1 mb-0">Email:</span
            ><span class="subtitle-1 text--primary ml-2" data-test="email">{{
              formEmail
            }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat class="ma-1">
      <v-card-text class="px-10">
        <v-row dense class="my-2">
          <v-col cols="12">
            <span class="title font-weight-bold">{{
              projectCompany
                ? projectCompany.name + " Profile Information"
                : "Request to join a Company Account"
            }}</span>
          </v-col>
        </v-row>
        <v-form lazy-validation ref="form">
          <div v-if="!projectCompany">
            <v-row dense class="pa-0 ma-0">
              <v-col cols="12" md="6">
                <span class="subtitle-1 mb-0">Company Name</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  name="companyname"
                  placeholder="New Company Name"
                  type="text"
                  autocomplete="off"
                  v-model="formCompanyRequest"
                  outlined
                  dense
                  readonly
                  class="mt-1 subtitle-1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-row dense v-show="Object.keys(quote).length > 0">
            <v-col cols="12" md="6">
              <BillingAddressForm
                ref="billing_address_form"
                :editMode="editMode"
                :user="projectUser"
                :company="projectCompany"
                :quote="quote"
                :billingAddress="billingAddress"
                :isReadOnly="false"
              />
            </v-col>
          </v-row>
          <v-row v-show="Object.keys(quote).length > 0" dense class="pa-0 ma-0">
            <v-col cols="12" md="6">
              <v-divider class="my-4" />
              <span class="subtitle-1">Shipping Address</span>
            </v-col>
          </v-row>
          <v-row v-show="Object.keys(quote).length > 0" dense>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="formShippingSame"
                label="Shipping Address same as Billing Address"
                color="secondary"
                dense
                hide-details
                class="mt-0 mb-5 body-1"
                data-test="shipping_same"
                @change="
                  updateShippingSame(formShippingSame)
                "
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row v-show="Object.keys(quote).length > 0" dense>
            <v-col cols="12" md="6">
              <transition name="slide">
                <div v-if="!formShippingSame">
                  <v-divider />
                  <ShippingAddressForm
                    ref="shipping_address_form"
                    key="project-flow"
                    :editMode="editMode"
                    :user="projectUser"
                    :company="projectCompany"
                    :quote="quote"
                    :shippingAddress="shippingAddress"
                    :isReadOnly="false"
                  />
                </div>
              </transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-spacer />
    <v-btn x-large color="secondary" class="ml-10 my-5" @click="onSaveUser" data-cy="fd-user-continue-button">{{
      (!inputValid ? "Save and " : "") + "Continue"
    }}</v-btn>
    <UserRelationModal
      v-if="userRelationModal"
      :dialog="userRelationModal"
      v-bind="$attrs"
      toolbarText="User"
      labelText="User Name"
      buttonText="Select a User to link this Project to"
      :arrayList="results"
      color=""
      @searchCreateRelation="onSearchCreateRelation"
      @onCreateRelation="createRelation"
      @onClearList="clearList"
      @onCloseUserRelationModal="closeUserRelationModal"
    />
  </v-sheet>
</template>
<script>
export default {
  name: "UserCompanyPage",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    projectUser: {
      type: Object,
      default: null,
    },
    projectCompany: {
      type: Object,
      default: null,
    },
    authenticatedUser: {
      type: Object,
      default: null,
    },
    inputValid: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    billingAddress: {
      type: Object,
      default: null,
    },
    shippingAddress: {
      type: Object,
      default: null,
    },
    uiShippingSameAsBilling: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    UserRelationModal: () => import("../components/UserRelationModal.vue"),
    BillingAddressForm: () =>
      import("@/modules/quotes/components/BillingAddressForm.vue"),
    ShippingAddressForm: () =>
      import("@/modules/quotes/components/ShippingAddressForm.vue"),
  },
  data() {
    return {
      userSelection: "2",
      formEmail: this.projectUser ? this.projectUser.email : null,
      formFullName: this.projectUser ? this.projectUser.full_name : null,
      formCompanyRequest: this.projectUser
        ? this.projectUser.company_request
        : null,
      formShippingSame: Object.keys(this.quote).length > 0 ? this.quote.shipping_same_as_billing : true,
      results: null,
      userRelationModal: false,
    };
  },
  methods: {
    onNextTab() {
      this.$emit("nextTab");
    },
    async onSearchCreateRelation(payload) {
      console.log("Search is clicked with string: ", payload);
      try {
        const response = await this.$store.dispatch("projects/searchUsers", {
          params: { search: payload },
        });
        this.results = response.map((element) => ({
          id: element.pk,
          name: element.email,
        }));
        console.log(this.results);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async createRelation({ id }) {
      console.log("Item is selected with id: ", id);
      this.userSelection = "1";
      this.$emit("onSelectedUser", id);
      this.userRelationModal = false;
      this.clearList();
    },
    clearList() {
      this.results = null;
    },
    refreshInputFields() {
      this.formEmail = this.projectUser.email || null;
      this.formFullName =
        this.projectUser.full_name.length > 0
          ? this.projectUser.full_name
          : this.projectUser.username;
      this.formCompanyRequest = this.projectUser.company_request || null;
      this.refreshUserSelection();
    },
    updateShippingSame(payload) {
      this.$store.dispatch("ui/updateShippingSame", payload);
    },
    refreshAddresses() {
      this.formShippingSame = Object.keys(this.quote).length > 0 ? this.quote.shipping_same_as_billing : true,
      this.updateShippingSame(this.quote.shipping_same_as_billing);
      this.$refs["billing_address_form"] && this.$refs["billing_address_form"].refreshAddressFields();
      this.$refs["shipping_address_form"] && this.$refs["shipping_address_form"].refreshAddressFields();
    },
    closeUserRelationModal() {
      this.userRelationModal = false;
      this.refreshUserSelection();
      this.clearList();
    },
    refreshUserSelection() {
      this.userSelection =
        this.projectUser.pk == this.authenticatedUser.pk ? "2" : "1";
    },
    onSaveUser() {
      if (this.inputValid || Object.keys(this.quote).length == 0) {
        this.onNextTab();
        return;
      }
      this.$refs["billing_address_form"] &&
        this.$refs["billing_address_form"].isBillingAddressValid();
      this.$refs["shipping_address_form"] &&
        this.$refs["shipping_address_form"].isShippingAddressValid();
      if (
        !this.isBillingAddressValid() ||
        (!this.uiShippingSameAsBilling && !this.isShippingAddressValid())
      ) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }
      this.$emit("onSaveUser", this.projectUser.pk);
      this.onNextTab();
    },
    isBillingAddressValid() {
      return (
        this.$refs["billing_address_form"] &&
        this.$refs["billing_address_form"].isBillingAddressValid()
      );
    },
    isShippingAddressValid() {
      return (
        this.$refs["shipping_address_form"] &&
        this.$refs["shipping_address_form"].isShippingAddressValid()
      );
    },
  },
  mounted() {
    this.userSelection =
      this.projectUser.pk == this.authenticatedUser.pk ? "2" : "1";
  },
};
</script>
